import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const NotFoundPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/');
    }, 5000);

    // Clear the timer if the component unmounts or the user navigates away
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div style={{ textAlign: 'center' }}>
      <Helmet>
        <title>ITCT TECH Solutions - 404 - Page Not Found</title>
        <meta name="description" content="The page you are looking for does not exist. You will be redirected to the homepage in 5 seconds." />
      </Helmet>
      <h1>ITCT TECH Solutions - 404 - Page Not Found</h1>
      <p>The page you are looking for does not exist. You will be redirected to the home page in 5 seconds.</p>
    </div>
  );
};

export default NotFoundPage;
