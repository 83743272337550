import { Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Home from './pages/Home';
import Layout from './components/Layout';
import NotFoundPage from './pages/NotFoundPage';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    Aos.init({
      once: true,
    });
  }, []);

  return (
    <HelmetProvider>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
      </Route>
      {/* Add a catch-all route for 404 errors */}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
    </HelmetProvider>
  );
}

export default App;