import React from 'react';
import { Helmet } from 'react-helmet-async';
import Hero from '../components/Hero';
import About from '../components/About';
import Service from '../components/Service';
import HomePagdData from '../data/HomePagdData.json';
import Contact from '../components/Contact';

export default function Home() {
  const {
    hero,
    socialBtns,
    about,
    service,
    contact,
  } = HomePagdData;

  return (
    <>
      <Helmet>
        <title>ITCT TECH Solutions - Your Partner in IT Excellence</title>
        <meta name="description" content="ITCT TECH Solutions offers proficient IT support services, ensuring prompt and reliable assistance for your technical needs, including website design, software development, web hosting, and IT infrastructure." />
      </Helmet>
      <Hero data={hero} socialData={socialBtns} />
      <About data={about} />
      <Service data={service} />
      <Contact data={contact} socialData={socialBtns} />
    </>
  );
}
